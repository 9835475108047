import React, { Fragment, useState } from 'react';

import useSpeechToText from './Hooks';
import Evaluate from './Evaluate';

import micIcon from './mic.png';
import voiceIcon from './voice.png';

import audio from './audio.mp3';

import './App.css';

const audioObj = new Audio(audio);
let isDemo = false

if (window.location.href.includes("demo")) {
  isDemo = true
}


let originTexts = [
  { index: 0, start: 3.5, end: 6.1, text: 'Modern communication is chaos!' },

  { index: 1, start: 6.1, end: 7.5, text: 'You email, You IM' },
  { index: 2, start: 7.5, end: 8.7, text: 'You text, You talk' },
  { index: 3, start: 8.7, end: 10.3, text: 'You CC, You BCC' },

  { index: 4, start: 10.3, end: 12.2, text: 'Upload files in one place' },
  { index: 5, start: 12.2, end: 13.6, text: 'link them somewhere else.' },
  { index: 6, start: 13.6, end: 15.6, text: 'Suddenly, someone needs a crucial file!' },

  { index: 7, start: 15.6, end: 17.8, text: 'So you pick through folders and inboxes' },
  { index: 8, start: 17.8, end: 19.2, text: 'third-party notifications' },
  { index: 9, start: 19.2, end: 20.3, text: 'threads and chats.' },

  { index: 10, start: 20.3, end: 21.6, text: 'Because you can\'t remember' },
  { index: 11, start: 21.6, end: 23.1, text: 'which way the message came' },
  { index: 12, start: 23.1, end: 26.1, text: 'or what exactly that attachment was attached to.' },


  { index: 13, start: 26.1, end: 27.3, text: 'It\'s a scramble' },
  { index: 14, start: 27.3, end: 28.3, text: 'You make it work' },
  { index: 15, start: 28.3, end: 29.5, text: 'But making it work' },
  { index: 16, start: 29.5, end: 30.5, text: 'is hard work.' },

  { index: 17, start: 30.5, end: 32.9, text: 'Life can be much simpler!' },
  { index: 18, start: 32.9, end: 35.0, text: 'Slack is simpler!' },
  { index: 19, start: 35.0, end: 37.7, text: 'Slack brings everything you need for team work' },
  { index: 20, start: 37.6, end: 38.7, text: 'into one place.' },

  { index: 21, start: 38.7, end: 41.6, text: 'Conversations happen in open channels:' },
  { index: 22, start: 41.6, end: 43.1, text: 'one for each project' },
  { index: 23, start: 43.1, end: 44.3, text: 'each department' },
  { index: 24, start: 44.3, end: 45.6, text: 'each office location.' },

  { index: 25, start: 45.6, end: 47.4, text: 'Here you can upload files' },
  { index: 26, start: 47.4, end: 48.3, text: 'drop links' },
  { index: 27, start: 48.3, end: 49.6, text: 'forward emails' },
  { index: 28, start: 49.6, end: 51.1, text: 'throw ideas around.' },

  { index: 29, start: 51.0, end: 52.3, text: 'a quick glance at a channel' },
  { index: 30, start: 52.3, end: 54.5, text: 'let\'s you know how a project is progressing' },
  { index: 31, start: 54.5, end: 56.3, text: 'or what a department is working on.' },

  { index: 32, start: 56.3, end: 57.9, text: 'If you\'re new' },
  { index: 33, start: 57.9, end: 59.9, text: 'you\'re not met by an empty inbox!' },
  { index: 34, start: 59.9, end: 62.5, text: 'You\'re met by the collective knowledge of the team' },
  { index: 35, start: 62.5, end: 64.9, text: 'nicely sorted into individual channels.' },

  { index: 36, start: 64.9, end: 67.5, text: 'And if someone moves on to a different project' },
  { index: 37, start: 67.5, end: 69.2, text: 'their input isn\'t locked away' },
  { index: 38, start: 69.2, end: 71.2, text: 'where old emails go to die.' },

  { index: 39, start: 71.2, end: 72.8, text: 'It\'s all there.' },

  { index: 40, start: 72.8, end: 74.0, text: 'A powerful search' },
  { index: 41, start: 74.0, end: 75.9, text: 'puts what you\'re looking for in your hands' },
  { index: 42, start: 75.9, end: 76.9, text: 'in milliseconds.' },
  { index: 43, start: 76.9, end: 78.2, text: 'Searching every channel' },
  { index: 44, start: 78.2, end: 79.0, text: 'every message' },
  { index: 45, start: 79.0, end: 81.2, text: 'every document ever uploaded.' },

  { index: 46, start: 81.2, end: 82.1, text: 'With Slack' },
  { index: 47, start: 82.1, end: 84.1, text: 'you\'re as in the loop as you want to be' },
  { index: 48, start: 84.1, end: 85.7, text: 'from wherever you are' },
  { index: 49, start: 85.7, end: 87.0, text: 'by setting notifications' },
  { index: 50, start: 87.0, end: 88.3, text: 'to the level you want.' },

  { index: 51, start: 88.3, end: 91.2, text: 'And all the services and tools you need' },
  { index: 52, start: 91.2, end: 93.1, text: 'integrate directly into the channels' },
  { index: 53, start: 93.1, end: 94.5, text: 'where you need to use them.' },

  { index: 54, start: 94.5, end: 96.7, text: 'It\'s about having all the information at hand' },
  { index: 55, start: 96.7, end: 98.2, text: 'to do whatever you do' },
  { index: 56, start: 98.2, end: 99.9, text: 'as well as you possibly can.' },

  { index: 57, start: 99.9, end: 101.5, text: 'more productive' },
  { index: 58, start: 101.5, end: 102.7, text: 'more transparent' },
  { index: 59, start: 102.7, end: 103.9, text: 'more efficient' },
  { index: 60, start: 103.9, end: 105.8, text: 'and no more email.' },

  { index: 61, start: 105.8, end: 107.2, text: 'That’s  Slack' }
];

// For the demo page, only include 5 lines
if (isDemo) {
  originTexts = [
    { index: 14, start: 27.3, end: 28.3, text: 'You make it work' },
    { index: 15, start: 28.3, end: 29.5, text: 'But making it work' },
    { index: 16, start: 29.5, end: 30.5, text: 'is hard work.' },

    { index: 17, start: 30.5, end: 32.9, text: 'Life can be much simpler!' },
    { index: 18, start: 32.9, end: 35.0, text: 'Slack is simpler!' },
  ]
}


export default function App() {
  const {
    error,
    interimResult,
    isRecording,
    results,
    startSpeechToText,
    stopSpeechToText
  } = useSpeechToText({
    continuous: true,
    crossBrowser: true,
    googleApiKey: process.env.REACT_APP_API_KEY,
    speechRecognitionProperties: { interimResults: true },
    timeout: 0
  });

  const [isPlaying, setIsPlaying] = useState(false);
  const [hightlight, setHighlight] = useState(-1);

  const refs = originTexts.reduce((acc, value) => {
    acc[value.index] = React.createRef();
    return acc;
  }, {});

  React.useEffect(() => {
    audioObj.addEventListener('timeupdate', function () {
      for (let i = 0; i < originTexts.length; i++) {
        if (originTexts[i].start < audioObj.currentTime && originTexts[i].end > audioObj.currentTime) {
          setHighlight(i);
          if (refs[i] && refs[i].current) {
            refs[i].current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }
        }
      }
    });
  });

  const startPlaying = () => {
    stopSpeechToText();

    audioObj.currentTime = 0;
    if (isDemo) {
      audioObj.currentTime = 27;
    }
    audioObj.play();

    setIsPlaying(true);

    if (isDemo) {
      setTimeout(function () {
        audioObj.pause();
        setIsPlaying(false);
        setHighlight(-1);
      }, (8 * 1000));
    } else {
      setTimeout(function () {
        audioObj.pause();
        setIsPlaying(false);
        setHighlight(-1);
      }, (107.2 * 1000));
    }

  }

  const pausePlaying = () => {
    setIsPlaying(false);
    setHighlight(-1);

    audioObj.pause();
  }

  const startRecording = () => {
    pausePlaying();
    startSpeechToText();
  }

  const stopRecording = () => {
    stopSpeechToText();
  }

  const handleClickItem = (position) => {
    audioObj.currentTime = originTexts[position]['start'];
    audioObj.play();
    setIsPlaying(true);

    setTimeout(function () {
      audioObj.pause();
      setIsPlaying(false);
      setHighlight(-1);
      audioObj.currentTime = 0;
    }, (originTexts[position]['end'] - originTexts[position]['start']) * 1000);

    console.log(position);
  }

  if (error) {
    return (
      <div
        style={{
          maxWidth: '600px',
          margin: '100px auto',
          textAlign: 'center'
        }}
      >
        <p>
          {error}
          <span style={{ fontSize: '3rem' }}>🤷‍</span>
        </p>
      </div>
    );
  }


  return (
    <div className="container pt-5">
      {!isDemo && <div className="d-flex justify-content-center align-items-center flex-column">
        <h2>Pitchloop Demo</h2>
        <h3>Try out pitchloop by practicing the full slack pitch below:</h3>
        <iframe src='https://www.youtube.com/embed/aloF_DCJ24A'
          frameborder='0'
          className="w-25 pt-3"
          allow='autoplay; encrypted-media'
          allowfullscreen
          title='video'
        />
      </div>}

      <div
        style={{
          maxWidth: '500px',
          margin: '50px auto',
          textAlign: 'left'
        }}
      >

        <div className="navigation">
          <div style={{ display: 'flex' }}>
            <button onClick={isPlaying ? pausePlaying : startPlaying}>
              <span>{isPlaying ? 'Stop Playing' : 'Start Playing'}</span>
            </button>
            <button onClick={isRecording ? stopRecording : startRecording}>
              <span>{isRecording ? 'Stop Recording' : 'Start Recording'}</span>
            </button>
          </div>
          <div style={{ height: '50px', textAlign: 'center' }}>
            {
              isRecording ?
                <img data-recording={isRecording} src={micIcon} alt="" style={{ maxWidth: '50px' }} />
                :
                ''
            }
            {
              isPlaying ?
                <img data-recording={isPlaying} src={voiceIcon} alt="" style={{ maxWidth: '50px' }} />
                :
                ''
            }
          </div>
        </div>

        {originTexts.map((originText, originIndex) => (
          <Fragment>
            <ul>
              <li key={originIndex} onClick={() => handleClickItem(originIndex)} style={originIndex === hightlight ? { 'color': 'green' } : {}} ref={refs[originText['index']]}>
                {originText['text']}
              </li>
            </ul>
            <ul>
              {results.map((result, index) => (
                index === originIndex ?
                  <li key={index} style={{ background: 'yellow' }}><Evaluate origin={originText['text']} result={result} isRecording={isRecording}></Evaluate></li> : ''
              ))}
              {results.length === originIndex && interimResult && <li style={{ background: 'yellow' }}>{interimResult}</li>}
            </ul>
          </Fragment>
        ))}
      </div>
    </div>

  );
}
