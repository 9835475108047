import React, { Component, Fragment } from "react";

interface Props {
    isRecording: boolean;
    result: string;
    origin: string;
}

export default class Evaluate extends Component<Props> {
    state = {
        evaluateResults: []
    }

    constructor(props: Props){
        super(props);
    }
      
    componentDidMount = () => {
        let origin = this.props.origin.toLowerCase().replace('.', '').replace(',', '').replace('!', '').split(' ');
        let result = this.props.result.toLowerCase().replace('.', '').replace(',', '').replace('!', '').split(' ');
        let evaluateResults = [];

        console.log(origin);
        console.log(result);

        for(let i=0; i<result.length; i++) {
            if(origin.indexOf(result[i]) > -1) {
                console.log(origin.indexOf(result[i]) + ', ' + result[i]);

                if(origin.indexOf(result[i]) !== 0) {
                    for(let k=0; k<origin.indexOf(result[i]); k++) {
                        evaluateResults.push({
                            value: origin[k],
                            status: 'missing'
                        });
                    }

                    origin.splice(0, origin.indexOf(result[i]));
                }

                evaluateResults.push({
                    value: result[i],
                    status: 'correct'
                });

                origin.splice(0, 1);
            }
            else {
                evaluateResults.push({
                    value: result[i],
                    status: 'incorrect'
                });
            }
        }

        console.log(evaluateResults);
        this.setState({
            evaluateResults: evaluateResults
        });
    }

    render() {
        return (
            <Fragment>
                {this.state.evaluateResults.map((result, index) => (
                    <span key={index} style={result.status === 'incorrect' ? {color: 'red'} : (result.status === 'missing' ? {color: 'blue'} : {color: 'green'})}>{result.status === 'incorrect' ? <s>{result.value}</s> : result.value} </span>
                ))}
            </Fragment>
        )
    }

}
